import axios from "axios";
import {tempPipelineBody} from "./tempPipelineBody";
import {tempChatPipelineBody} from "./tempChatPipelineBody";
import {generateComparisonPrompt} from "./comparisonPrompt";
import {getConfig} from "../config";

export type PipelineBody = {
    userInput: string;
    asyncOutput: boolean;
    debug?: boolean;
    userId?: string;
    conversationId?: string;
    image?: string[];
};

export interface ChatMessage {
    type: 'user' | 'assistant';
    text: string;
    timestamp: string;
}

const {apiUrl} = getConfig()

const client = axios.create({
    baseURL: apiUrl,
    timeout: 120000,
})

export const ExecuteChatPipeline = async (userInput: string, accessToken: string): Promise<ChatMessage> => {
    const response = await client.post(`/PipelineExecution/TemporaryPipeline`, tempChatPipelineBody(userInput), {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        },
    });
    if (response.status !== 200) {
        throw new Error("Failed to execute chat pipeline");
    }
    return {
        type: 'assistant',
        text: response.data.result,
        timestamp: new Date().toLocaleString(),
    };
};

export const ExecutePrivateChatPipeline = async (userInput: string, accessToken: string): Promise<ChatMessage> => {
    const response = await client.post(`/PipelineExecution/TemporaryPipeline`, tempPipelineBody(userInput), {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
        },
    });
    if (response.status !== 200) {
        throw new Error("Failed to execute chat pipeline");
    }
    return {
        type: 'assistant',
        text: response.data.result,
        timestamp: new Date().toLocaleString(),
    };
};

export const executeComparisonPipeline = async (userInput: {
    document: string;
    playbook: string
}, token: string): Promise<string> => {
    const input = generateComparisonPrompt(userInput.playbook, userInput.document);
    const response = await client.post(`/PipelineExecution/TemporaryPipeline`, tempPipelineBody(input), {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        timeout: 120000,
    });

    if (response.status !== 200) {
        throw new Error("Failed to build temp pipeline");
    }
    return response.data.result.replace(/\\[ntr]/g, '') as string
}
