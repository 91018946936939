import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export const getDocumentText = () => {
    return new Promise((resolve, reject) => {
        Word.run(async (context) => {
            const body = context.document.body;
            context.load(body, 'text');
            await context.sync();
            resolve(body.text);
        }).catch(reject);
    });
};

export const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            console.log('Text copied to clipboard');
        })
        .catch((error) => {
            console.error('Failed to copy text: ', error);
        });
}

export const replaceSync = async (documentText: string, replaceWithText: string) => {
    try {
        await Word.run(async (context) => {
            let body = context.document.body;
            let searchResults = body.search(documentText, {matchCase: false, matchWholeWord: false});
            context.load(searchResults, 'text');

            await context.sync();

            // Highlight the found text
            searchResults.items[0].font.highlightColor = '#FFFF00';  // Yellow highlight
            searchResults.items[0].select();
            await context.sync();

            // Ask for user confirmation
            if (await confirmReplace()) {
                searchResults.items[0].insertText(replaceWithText, Word.InsertLocation.replace);
            } else {
                searchResults.items[0].font.highlightColor = null; // Remove highlight
            }

            // Remove highlight after decision
            searchResults.items[0].font.highlightColor = 'None';
            await context.sync();
        })
    } catch (error) {
        console.error('Error: ' + error);
    }
}

export const isInDocument = async (searchText: string): Promise<boolean> => {
    try {
        return new Promise((resolve) => {
            Word.run(async (context) => {
                let body = context.document.body;
                let searchResults = body.search(searchText, {matchCase: false, matchWholeWord: false});
                context.load(searchResults, 'text');
                await context.sync();
                resolve(searchResults.items.length > 0);
            });
        })
    } catch (error) {
        console.error('Error: ' + error);
        return false;
    }
}

function confirmReplace() {
    return new Promise((resolve) => {
        let confirmBtn = document.getElementById('confirmBtn');
        let cancelBtn = document.getElementById('cancelBtn');

        confirmBtn.onclick = () => {
            resolve(true);
        };

        cancelBtn.onclick = () => {
            resolve(false);
        };
    });
}