import React, {ReactNode, useState} from "react";
import ChatContext from "./ChatContext";
import {Playbook as IPlaybook} from "../../services/playbook";
import {Dataset} from "../../services/datasetModels";
import {ChatMessage} from "../../services/pipeline";

interface ChatProviderProps {
    children: ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({children}) => {
    const [isConfidential, setIsConfidential] = useState<boolean>(true);
    const [isLibraryOpen, setIsLibraryOpen] = useState<boolean>(false);
    const [playbooks, setPlaybooks] = useState<IPlaybook[]>([])
    const [documents, setDocuments] = useState<(Dataset & { fileCount: number })[]>([])
    const [projectId, setProjectId] = useState<string>('');
    const [confidentialMessages, setConfidentialMessages] = useState<ChatMessage[]>([])
    const [openMessages, setOpenMessages] = useState<ChatMessage[]>([])

    return (
        <ChatContext.Provider value={{
            isConfidential,
            setIsConfidential,
            isLibraryOpen,
            setIsLibraryOpen,
            playbooks,
            setPlaybooks,
            documents,
            setDocuments,
            projectId,
            setProjectId,
            confidentialMessages,
            setConfidentialMessages,
            openMessages,
            setOpenMessages,
        }}>
            {children}
        </ChatContext.Provider>
    );
};