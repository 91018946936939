import React, {useCallback } from 'react';
import {useDropzone} from "react-dropzone";
import {ArrowUpCircle} from "lucide-react";
import {Button} from "../components/ui/button";

interface PlaybookDetailsProps {
    onFileAttach: (file: File) => void
}

const UploadFile: React.FC<PlaybookDetailsProps> = ({ onFileAttach }) => {

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        onFileAttach(acceptedFiles[0])
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()}
             className={`w-full h-full flex flex-col justify-center items-center gap-10 rounded-lg border-4 border-dashed border-[#EAEAEA] ${isDragActive ? 'bg-[#E6E1FA]' : ''}`}>
            <ArrowUpCircle className={'h-24 w-24 text-[#48549D]'}/>
            <input {...getInputProps()} />
            <div className={'flex flex-col justify-center'}>
            <span className={'text-[#3d3d39]'}>
                {`Drag your files here to upload or`}
            </span>
                <Button variant={'ghost'} className={'text-[#5C33FF] font-bold'}>
                    Browse files
                </Button>
            </div>
        </div>
    )
}

export default UploadFile