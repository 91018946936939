import {Stars} from "lucide-react";
import {Button} from "./ui/button";
import * as React from "react";

const PromptSuggestion: React.FC<{ label: string; onClick?: (label: string) => void }> = ({label, onClick}) => {
    return (
        <Button
            variant="outline"
            className="w-full rounded-lg justify-start text-left text py-3 px-2 hover:bg-[#E6E1FA]"
            onClick={() => onClick && onClick(label)}>
            <Stars className="w-5 h-5 mr-2 text-[#5C33FF]"/>
            {label}
        </Button>
    )
}

export default PromptSuggestion