import {Input} from "./ui/input";
import {Button} from "./ui/button";
import {ArrowUp, Stars} from "lucide-react";
import * as React from "react";
import {useChat} from "../../context/chat/ChatContext";

interface UserChatInputProps {
    onMessageSend: (event: React.FormEvent<HTMLFormElement>) => void;
    onChange: (message: string) => void;
    value: string;
}

const UserChatInput: React.FC<UserChatInputProps> = ({onMessageSend, onChange, value}) => {
    const { isLibraryOpen, setIsLibraryOpen} = useChat()

    const onLibraryOpenClick = () => {
        setIsLibraryOpen(!isLibraryOpen)
    }
    return (
        <form onSubmit={e => onMessageSend(e)}>
            <div className="min-h-[90px] flex items-center justify-between p-4 border-t bg-white">
                <div onClick={() => onLibraryOpenClick()}
                     className={`cursor-pointer p-4 ${isLibraryOpen ? 'text-[#5C33FF] bg-[#EBEBFF] rounded-full' : 'text-[#7b7b7b]'}`}>
                    <Stars/>
                </div>
                <Input placeholder="Ask Airia..."
                       value={value}
                       onChange={e => onChange(e.target.value)}
                       className="text-black grow text-xl border-none mr-1"/>
                <Button size={'icon'}
                        type={'submit'}
                        className={'bg-[#5C33FF] text-white w-10 h-10 rounded-full flex items-center justify-center'}>
                    <ArrowUp className={'h-4 w-4'}/>
                </Button>
            </div>
        </form>
    )
}

export default UserChatInput
