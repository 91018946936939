import { createContext, useContext } from 'react';
import {Playbook} from "../../services/playbook";
import {Dataset} from "../../services/datasetModels";
import {ChatMessage} from "../../services/pipeline";

interface ChatContextProps {
    isConfidential: boolean;
    setIsConfidential: (value: boolean) => void;
    isLibraryOpen: boolean;
    setIsLibraryOpen: (value: boolean) => void;
    projectId: string;
    setProjectId: (value: string) => void;
    playbooks: Playbook[]
    setPlaybooks: (value: (prev: Playbook[]) => Playbook[]) => void
    documents: (Dataset & { fileCount: number })[]
    setDocuments: (value: (Dataset & { fileCount: number })[]) => void
    confidentialMessages: ChatMessage[]
    setConfidentialMessages: (value: (prev: ChatMessage[]) => ChatMessage[]) => void
    openMessages: ChatMessage[]
    setOpenMessages: (value: (prev: ChatMessage[]) => ChatMessage[]) => void
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const useChat = (): ChatContextProps => {
    const context = useContext(ChatContext);
    if (!context) {
        throw new Error('useChat must be used within a ChatProvider');
    }
    return context;
};

export default ChatContext