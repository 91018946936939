export const tempPipelineBody = (userInput: string ) => ({
    "pipeline": {
        "id": "f5156f08-a192-432b-b909-b3baa4978aef",
        "description": "Temporary Pipeline for Playground",
        "name": "Temporary Playground Pipeline",
        "executionName": "temporary_playground_pipeline",
        "createdAt": "2024-08-26T12:10:39.403Z",
        "updatedAt": "2024-08-26T12:10:39.403Z",
        "projectId": "df36b97e-c2b1-4221-9c34-ca89ad111834",
        "activeVersionId": "440038de-6c30-4407-b01c-051fbd65de62",
        "activeVersion": {
            "id": "440038de-6c30-4407-b01c-051fbd65de62",
            "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
            "versionNumber": "1.00",
            "majorVersion": 1,
            "minorVersion": 0,
            "isDraftVersion": false,
            "isLatest": true,
            "steps": [
                {
                    "stepType": "outputStep",
                    "stepId": "6370e1d4-19ce-42db-96d3-66386de158bf",
                    "position": {
                        "id": "56860871-57c7-4390-b234-7e2be97e4648",
                        "x": 800,
                        "y": 450,
                        "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                        "projectId": "00000000-0000-0000-0000-000000000000",
                        "createdAt": "2024-08-26T11:51:16.747446Z",
                        "updatedAt": "2024-08-26T11:51:16.747446Z",
                        "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                    },
                    "dependencies": [
                        "ae20f0e3-dc4a-4174-be10-add00b332a9f"
                    ],
                    "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
                    "pipelineVersionId": "f7c6c077-8489-4b1a-b970-f625784fe69b",
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "df36b97e-c2b1-4221-9c34-ca89ad111834",
                    "createdAt": "2024-08-09T14:46:28.074628Z",
                    "updatedAt": "2024-08-09T14:46:28.074628Z",
                    "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                },
                {
                    "stepType": "inputStep",
                    "stepId": "8c69529a-5609-4f06-9c05-8b8f420724a9",
                    "position": {
                        "id": "1a8fc5f9-959c-4ed4-b0cb-149766070bc5",
                        "x": 200,
                        "y": 450,
                        "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                        "projectId": "00000000-0000-0000-0000-000000000000",
                        "createdAt": "2024-08-26T11:51:16.747444Z",
                        "updatedAt": "2024-08-26T11:51:16.747444Z",
                        "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                    },
                    "dependencies": [],
                    "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
                    "pipelineVersionId": "f7c6c077-8489-4b1a-b970-f625784fe69b",
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "df36b97e-c2b1-4221-9c34-ca89ad111834",
                    "createdAt": "2024-08-09T14:46:28.074581Z",
                    "updatedAt": "2024-08-09T14:46:28.074581Z",
                    "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                },
                {
                    "modelId": "613f0355-ea4b-4fec-9874-f4054cf095b2",
                    "temperature": 1.00,
                    "includeDateTimeContext": false,
                    "promptId": null,
                    "selectedPromptVersion": null,
                    "stepType": "AIOperation",
                    "stepId": "ae20f0e3-dc4a-4174-be10-add00b332a9f",
                    "position": {
                        "id": "25eda518-902d-4fd6-b13d-b4c2c3d3deee",
                        "x": 440.6111,
                        "y": 355.77777,
                        "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                        "projectId": "00000000-0000-0000-0000-000000000000",
                        "createdAt": "2024-08-26T11:51:16.747441Z",
                        "updatedAt": "2024-08-26T11:51:16.747441Z",
                        "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                    },
                    "dependencies": [
                        "8c69529a-5609-4f06-9c05-8b8f420724a9"
                    ],
                    "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
                    "pipelineVersionId": "f7c6c077-8489-4b1a-b970-f625784fe69b",
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "df36b97e-c2b1-4221-9c34-ca89ad111834",
                    "createdAt": null,
                    "updatedAt": null,
                    "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                }
            ]
        },
        "versions": [
            {
                "id": "440038de-6c30-4407-b01c-051fbd65de62",
                "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
                "versionNumber": "1.00",
                "majorVersion": 1,
                "minorVersion": 0,
                "isLatest": true,
                "steps": [
                    {
                        "stepType": "outputStep",
                        "stepId": "6370e1d4-19ce-42db-96d3-66386de158bf",
                        "position": {
                            "id": "56860871-57c7-4390-b234-7e2be97e4648",
                            "x": 800,
                            "y": 450,
                            "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                            "projectId": "00000000-0000-0000-0000-000000000000",
                            "createdAt": "2024-08-26T11:51:16.747446Z",
                            "updatedAt": "2024-08-26T11:51:16.747446Z",
                            "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                        },
                        "dependencies": [
                            "ae20f0e3-dc4a-4174-be10-add00b332a9f"
                        ],
                        "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
                        "pipelineVersionId": "f7c6c077-8489-4b1a-b970-f625784fe69b",
                        "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                        "projectId": "df36b97e-c2b1-4221-9c34-ca89ad111834",
                        "createdAt": "2024-08-09T14:46:28.074628Z",
                        "updatedAt": "2024-08-09T14:46:28.074628Z",
                        "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                    },
                    {
                        "stepType": "inputStep",
                        "stepId": "8c69529a-5609-4f06-9c05-8b8f420724a9",
                        "position": {
                            "id": "1a8fc5f9-959c-4ed4-b0cb-149766070bc5",
                            "x": 200,
                            "y": 450,
                            "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                            "projectId": "00000000-0000-0000-0000-000000000000",
                            "createdAt": "2024-08-26T11:51:16.747444Z",
                            "updatedAt": "2024-08-26T11:51:16.747444Z",
                            "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                        },
                        "dependencies": [],
                        "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
                        "pipelineVersionId": "f7c6c077-8489-4b1a-b970-f625784fe69b",
                        "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                        "projectId": "df36b97e-c2b1-4221-9c34-ca89ad111834",
                        "createdAt": "2024-08-09T14:46:28.074581Z",
                        "updatedAt": "2024-08-09T14:46:28.074581Z",
                        "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                    },
                    {
                        "modelId": "613f0355-ea4b-4fec-9874-f4054cf095b2",
                        "temperature": 1,
                        "includeDateTimeContext": false,
                        "promptId": null,
                        "selectedPromptVersion": null,
                        "stepType": "AIOperation",
                        "stepId": "ae20f0e3-dc4a-4174-be10-add00b332a9f",
                        "position": {
                            "id": "25eda518-902d-4fd6-b13d-b4c2c3d3deee",
                            "x": 440.6111,
                            "y": 355.77777,
                            "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                            "projectId": "00000000-0000-0000-0000-000000000000",
                            "createdAt": "2024-08-26T11:51:16.747441Z",
                            "updatedAt": "2024-08-26T11:51:16.747441Z",
                            "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                        },
                        "dependencies": [
                            "8c69529a-5609-4f06-9c05-8b8f420724a9"
                        ],
                        "pipelineId": "f5156f08-a192-432b-b909-b3baa4978aef",
                        "pipelineVersionId": "f7c6c077-8489-4b1a-b970-f625784fe69b",
                        "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                        "projectId": "df36b97e-c2b1-4221-9c34-ca89ad111834",
                        "createdAt": null,
                        "updatedAt": null,
                        "userId": "9a3c7c44-775c-4cfa-9be9-59edbda645cf"
                    }
                ]
            }
        ],
        "executionStats": {
            "successCount": 0,
            "failureCount": 0
        }
    },
    "userInput": JSON.stringify(userInput),
    "images": [],
    "temporaryPrompt": "",
    "saveHistory": false,
    "debug": true,
    "asyncOutput": false,
    "inMemoryMessages": [],
    "conversationId": "a985c294-5d6a-4917-9fb2-b7fcc01f5180"
})
