import React, {useState} from "react";
import {CheckCircle, Folder, Search} from "lucide-react";
import {Input} from "../components/ui/input";
import {Dataset} from "../../services/datasetModels";
import fakeDatasets from "../../services/fakeDatasets";

const DocumentListItem: React.FC<{ document: (Dataset & { fileCount: number }) }> = ({document}) => {
    const [active, setActive] = useState(false)

    const onClick = () => setActive(prev => !prev)
    return (
        <div onClick={() => onClick()} className={'flex items-center justify-between border-b border-[#EAEAEA] py-4'}>
            <div className={'flex gap-4 items-center'}>
                <Folder className={'h-6 w-6'}/>
                <div className={'flex flex-col'}>
                    <span>{document.name}</span>
                    <span className={'text-[#5C33FF]'}>{document.fileCount} file(s)</span>
                </div>
            </div>
            {active && <div>
                <CheckCircle className={'h-6 w-6 text-[#25C822]'}/>
            </div>}
        </div>
    )
}

const Documents: React.FC = () => {
    const [documents, setDocments] = useState(fakeDatasets.items)

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (value === '') {
            setDocments(fakeDatasets.items)
        } else {
            const filtered = fakeDatasets.items.filter(d => d.name.toLowerCase().includes(value.toLowerCase()))
            setDocments(filtered)
        }
    }

    return (
        <div className={'px-4 bg-white'}>
            {documents.length > 0 && (
                <div className={'flex items-center border-b border-border'}>
                    <Search/>
                    <Input placeholder={'Search Documents'} onChange={onSearch} className={'border-none'}/>
                </div>
            )}
            {
                documents.length === 0 && <div>
                    <h1 className={'text-xl text-center'}>No documents uploaded.</h1>
                </div>
            }
            {
                documents.map(document => (
                    <DocumentListItem key={document.id} document={document} />
                ))
            }
        </div>
    )
}

export default Documents