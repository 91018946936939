import * as React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import {AuthProvider, AuthProviderProps} from "react-oidc-context";
import App from "./App";
import {Log} from "oidc-client-ts";
import {getConfig} from "../config";

const { authority, clientId, redirectUri, popup_post_logout_redirect_uri } = getConfig();
const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

const oidcConfig: AuthProviderProps = {
    authority: authority,
    client_id: clientId,
    redirect_uri: redirectUri,
    popup_post_logout_redirect_uri,
};

/* Render application after Office initializes */
Office.onReady(() => {
    Log.setLogger(console);
    Log.setLevel(Log.DEBUG);
    root?.render(
        <AuthProvider {...oidcConfig}>
            <App/>
        </AuthProvider>
    );
});

if ((module as any).hot) {
    (module as any).hot.accept("./App", () => {
        const NextApp = require("./App").default;
        root?.render(NextApp);
    });
}
