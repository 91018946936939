import {DataSourceList} from "./datasetModels";

const fakeDatasets: DataSourceList = {
    "items": [
        {
            "id": "019e0a95-9717-4351-b8a3-bb54367d41db",
            "name": "Playbooks",
            "executionName": "playbook",
            "state": "Finished",
            "fileCount": 12,
            "createdAt": "2024-08-02T23:12:58.293482Z",
            "updatedAt": "2024-08-02T23:12:58.36572Z",
            "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
            "databaseType": "mongodb"
        },
        {
            "id": "01b08407-47ab-4986-8afe-0c19f94d59bd",
            "name": "Scanned Files",
            "executionName": "scannedfiles",
            "state": "Finished",
            "fileCount": 25,
            "createdAt": "2024-07-30T20:59:14.532444Z",
            "updatedAt": "2024-07-30T20:59:14.63712Z",
            "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
            "databaseType": "mongodb"
        },
        {
            "id": "1ce8a07e-6d4f-495a-be38-4655b9a9199c",
            "name": "IRS",
            "executionName": "irsmongo",
            "state": "Finished",
            "fileCount": 53,
            "createdAt": "2024-08-08T15:18:23.410139Z",
            "updatedAt": "2024-08-08T15:21:09.473727Z",
            "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
            "databaseType": "mongodb"
        },
        {
            "id": "5d98472d-6fc4-4c35-bd7c-453483e1cecf",
            "name": "Tax",
            "executionName": "tax",
            "state": "Finished",
            "fileCount": 13,
            "createdAt": "2024-08-09T03:35:51.316613Z",
            "updatedAt": "2024-08-09T03:36:04.797322Z",
            "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
            "databaseType": "mongodb"
        },
        {
            "id": "615be35e-a655-4873-9acc-400e8a89fa7f",
            "name": "Cloud Drive",
            "executionName": "cloudkit_test_with_pinecone",
            "state": "Finished",
            "fileCount": 27,
            "createdAt": "2024-07-15T19:43:01.996619Z",
            "updatedAt": "2024-07-15T19:43:02.000376Z",
            "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
            "databaseType": "pinecone"
        },
        {
            "id": "6f6d4f02-5259-406f-819c-5ab6cbe6fad0",
            "name": "Legal files",
            "executionName": "test_legal_files",
            "state": "Finished",
            "fileCount": 58,
            "createdAt": "2024-07-31T14:48:14.912946Z",
            "updatedAt": "2024-07-31T14:48:15.042309Z",
            "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
            "databaseType": "mongodb"
        },
    ],
    "totalCount": 39
}

export default fakeDatasets