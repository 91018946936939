export const generateComparisonPrompt = (playbook: string, document: string): string => {
    return `Below is an instruction that describes a task, paired with an input that provides further context. Write a response that appropriately completes the request.
### Instruction:
Act as a specialist lawyer tasked with comparing the contents of a specific legal AGREEMENT against a legal PLAYBOOK. Your objective is to analyze how well the CONTRACT aligns with the guidelines, clauses, rules, and best practices outlined in the PLAYBOOK.
Playbook Definition: Playbooks are predefined guidelines, rules, and templates that outline the step-by-step process for reviewing and managing contracts within legal and business teams. They ensure consistent and efficient contract handling by creating structured workflows, standardized language, and best practices tailored to specific contract types and scenarios. They act as roadmaps for reviewers, providing step-by-step instructions on navigating various aspects of business requirements, compliance obligations, data protection, and more.
Produce as many corrections as possible.
 
### Input:
### PLAYBOOK:
${playbook}

### AGREEMENT:
${document}

### FORMATTING:
Response should be only JSON without any additional text outside. Do not add anything additionally except pure valid json.
Produce an output in the JSON format as:
 
[{"original": "ORIGINAL TEXT INCOMPLIANT",
  "suggested" : "IMPROVED TEXT ACCORDING TO THE PLAYBOOK",
  "reason": "PROVIDE A REASON WHY DIFFERENT VARIANT WAS SUGGESTED"}]
 
 
### Response:`
}
