import React, {useEffect} from "react";
import {Drawer, DrawerContent, DrawerHeader, DrawerTrigger} from "../components/ui/drawer";
import Chat from "./Chat";
import {ArrowUp, Plus, X} from "lucide-react";
import {Button} from "../components/ui/button";
import UploadFile from "./UploadFile";
import ChatDrawerHeader from "../components/ChatDrawerHeader";
import PlaybookDetails from "./PlaybookDetails";
import {ComparisonV2, Playbook as IPlaybook} from "../../services/playbook";
import {ProfileDropdown} from "../components/ProfileDropdown";
import {extractRawText} from "mammoth";
import {AxiosError} from "axios";
import PlaybookList from "./PlaybookList";
import Documents from "./Documents";
import {GetAllProjects} from "../../services/datasets";
import {useAuth} from "react-oidc-context";
import {useChat} from "../../context/chat/ChatContext";

const Playbook: React.FC = () => {
    const [view, setView] = React.useState<'playbooks' | 'documents'>('playbooks')
    const [chatDrawerOpen, setChatDrawerOpen] = React.useState<boolean>(false);
    const [uploadDrawerOpen, setUploadDrawerOpen] = React.useState<boolean>(false);
    const [selectedPlaybook, setSelectedPlaybook] = React.useState<IPlaybook | null>(null);
    const {user} = useAuth()
    const {setProjectId, setPlaybooks} = useChat()

    useEffect(() => {
        GetAllProjects(user.access_token).then(projects => {
            // const projectId = projects.find(p => p.projectType === 'Legal')?.id
            const projectId = projects.find(p => p.name === 'Default App')?.id
            if (projectId) {
                setProjectId(projectId)
            }
        }).catch(err => {
            console.error(err)
        })
    }, []);

    const onFileAttach = async (file: File) => {
        setUploadDrawerOpen(false)
        const playbookId = Math.random()
        const playbook: IPlaybook = {
            id: playbookId,
            name: file.name,
            status: 'Processing',
            clauses: '',
            comparisons: []
        }
        setPlaybooks((prev: IPlaybook[]) => [...prev, playbook])
        try {
            const arrayBuffer = await file.arrayBuffer()
            const result = await extractRawText({arrayBuffer})
            playbook.clauses = result.value
            playbook.status = 'Completed'
            setPlaybooks(prev => {
                const index = prev.findIndex(p => p.id === playbookId)
                prev[index] = playbook
                return [...prev]
            })
        } catch (err) {
            playbook.status = 'Failed'
            setPlaybooks(prev => {
                const index = prev.findIndex(p => p.id === playbookId)
                prev[index] = playbook
                return [...prev]
            })
            if (err instanceof AxiosError) {
                console.error('error executing the pipeline', err.response?.data)
            } else {
                console.error('error parsing the document', err)
            }
        }
    }

    const onPlaybookClick = (playbook: IPlaybook) => {
        setSelectedPlaybook(playbook)
    }

    const onComparisonPipelineExecution = async (playbookId: number, comparisons: ComparisonV2[]) => {
        setPlaybooks(prev => {
            const index = prev.findIndex(p => p.id === playbookId)
            prev[index].comparisons = comparisons
            return [...prev]
        })
    }

    return (
        (selectedPlaybook === null ? <div className={'h-screen w-full flex flex-col bg-white'}>
            <Drawer open={uploadDrawerOpen} onOpenChange={setUploadDrawerOpen}>
                <div className={'w-full flex justify-between items-center p-4'}>
                    <div className={'flex gap-4 items-center'}>
                        <span onClick={() => setView('playbooks')}
                              className={`px-3 py-1 cursor-pointer ${view === 'playbooks' ? 'text-[#5C33FF] bg-[#EBEBFF] rounded-full' : ''}`}>Playbooks</span>
                        <span onClick={() => setView('documents')}
                              className={`px-3 py-1 cursor-pointer ${view === 'documents' ? 'text-[#5C33FF] bg-[#EBEBFF] rounded-full' : ''}`}>Documents</span>
                    </div>
                    <div className={'flex gap-2'}>
                        <DrawerTrigger>
                            <Button variant={'ghost'} size={'icon'}>
                                <Plus/>
                            </Button>
                        </DrawerTrigger>
                        <ProfileDropdown/>
                    </div>
                </div>
                <DrawerContent className={'h-[50vh] bg-white p-4'}>
                    <DrawerHeader>
                        <div className={'flex justify-between items-center'}>
                            <h1>{`Upload ${view === 'playbooks' ? 'Playbooks' : 'Documents'}`}</h1>
                            <Button variant={'ghost'} size={'icon'} onClick={() => setUploadDrawerOpen(false)}
                                    className={'text-[#848996]'}>
                                <X/>
                            </Button>
                        </div>
                    </DrawerHeader>
                    <UploadFile onFileAttach={onFileAttach}/>
                </DrawerContent>
            </Drawer>
            <Drawer open={chatDrawerOpen} onOpenChange={setChatDrawerOpen}>
                <div className={'flex flex-col justify-between h-full'}>
                    {view === 'playbooks'
                        ? <PlaybookList onPlaybookClick={onPlaybookClick}/>
                        : <Documents/>
                    }
                    <DrawerTrigger>
                        <div className="flex items-center justify-between p-4 border-t bg-white">
                            <div className="flex grow text-xl">
                                <span className={'text-gray-400'}>Ask Airia...</span>
                            </div>
                            <Button size={'icon'}
                                    className={'bg-[#5C33FF] text-white w-10 h-10 rounded-full flex items-center justify-center'}>
                                <ArrowUp className={'h-4 w-4'}/>
                            </Button>
                        </div>
                    </DrawerTrigger>
                </div>
                <DrawerContent className={'h-[95vh] -mb-4'}>
                    <DrawerHeader className={'bg-white rounded-t-lg'}>
                        <ChatDrawerHeader onClose={() => setChatDrawerOpen(false)}/>
                    </DrawerHeader>
                    <Chat/>
                </DrawerContent>
            </Drawer>
        </div> : <PlaybookDetails onBack={() => setSelectedPlaybook(null)} playbook={selectedPlaybook}
                                  onComparisonPipelineExecution={onComparisonPipelineExecution}/>)
    );
}

export default Playbook;
