import {Card, CardContent} from "./ui/card";
import React, {useEffect} from "react";
import {Input} from "./ui/input";
import {Separator} from "./ui/separator";
import {prompts as lib_prompts} from "../../services/promptLibPrompts";
import PromptSuggestion from "./PromptSuggestion";

const PROMPT_LIBRARY = lib_prompts

const PromptLibraryCategory: React.FC<{ category: string, active: boolean, onClick: () => void }> =
    ({
         category,
         active,
         onClick
     }) => {
        return (
            <span onClick={onClick}
                  className={`px-3 py-1 rounded-full cursor-pointer ${active ? 'text-[#5C33FF] bg-[#EBEBFF]' : ''} hover:bg-[#EBEBFF]`}
            >
                {category}
            </span>
        )
    }

const PromptLibrary: React.FC<{onPromptClick: (prompt: string) => void}> = ({ onPromptClick }) => {
    const [activeCategory, setActiveCategory] = React.useState<string>('Personal')
    const [prompts, setPrompts] = React.useState<{ name: string; prompt: string }[]>(PROMPT_LIBRARY.personal)
    const [filteredPrompts, setFilteredPrompts] = React.useState<{ name: string; prompt: string }[]>(prompts)

    useEffect(() => {
        setFilteredPrompts(prompts)
    }, [prompts]);

    const onSearch = (search: string) => {
        if (search === '') {
            setFilteredPrompts(prompts)
        } else {
            setFilteredPrompts(prompts.filter(p => p.name.toLowerCase().includes(search.toLowerCase())))
        }
    }

    const onCategoryChange = (category: string) => {
        setActiveCategory(category)
        setPrompts(PROMPT_LIBRARY[category.toLowerCase()])
    }

    const onPromptItemClick = (prompt: string) => {
        const result = prompts.find(p => p.name === prompt)
        if (result) {
            onPromptClick(result.prompt)
        }

    }

    return (
        <Card className={'border border-[0.5] border-[#5C33FF]'}>
            <CardContent className={'z-50'}>
                <div className="flex flex-col gap-2 py-4 border-t bg-white max-h-[500px]">
                    <Input onChange={e => onSearch(e.target.value)} placeholder={'Search'} className="border-none"/>
                    <Separator className={'border border-[#EAEAEA]'}/>
                    <div className={'flex gap-2'}>
                        {['Personal', 'Company', 'Airia']
                            .map(c =>
                                <PromptLibraryCategory
                                    key={`category-${c}`}
                                    category={c}
                                    active={activeCategory === c}
                                    onClick={() => onCategoryChange(c)}/>
                            )
                        }
                    </div>
                    <div className={'flex flex-col gap-2 w-full overflow-y-auto'}>
                        {filteredPrompts.map(p =>
                            <PromptSuggestion key={`prompt-${p}`} label={p.name} onClick={onPromptItemClick}/>)}
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default PromptLibrary