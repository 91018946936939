import React, {useEffect} from "react";
import {Button} from "../components/ui/button";
import {ComparisonV2, Playbook} from "../../services/playbook";
import {ArrowLeft} from "lucide-react";
import {Progress} from "../components/ui/progress";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "../components/ui/dialog";
import {executeComparisonPipeline} from "../../services/pipeline";
import {getDocumentText, isInDocument} from "../../lib/utils";
import ComparisonCard from "../components/ComparisonCard";
import {LoadingSpinner} from "../components/ui/loading-spinner";
import {useAuth} from "react-oidc-context";
import { jsonrepair } from 'jsonrepair'

interface PlaybookDetailsProps {
    playbook: Playbook
    onBack: () => void;
    onComparisonPipelineExecution: (playbookId: number, comparisons: ComparisonV2[]) => void;
}

const PlaybookDetails: React.FC<PlaybookDetailsProps> = ({onBack, playbook, onComparisonPipelineExecution}) => {
    const [activeComparison, setActiveComparison] = React.useState(0);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const {user} = useAuth()

    useEffect(() => {
        if (playbook.clauses.length > 0) {
            setIsLoading(true)
            getDocumentText().then((text: string) => {
                executeComparisonPipeline({
                    playbook: playbook.clauses,
                    document: text,
                }, user.access_token).then(async comparisons => {
                    let response: ComparisonV2[];
                    try {
                        response = JSON.parse(comparisons) as ComparisonV2[]
                    } catch (err) {
                        // Sometimes LLM responses are not valid JSON
                        if (err instanceof SyntaxError) {
                            const repaired = jsonrepair(comparisons)
                            response = JSON.parse(repaired) as ComparisonV2[]
                        } else {
                            console.error('Error parsing comparison response', err)
                        }
                    }
                    const matchingComparisons: ComparisonV2[] = []
                    for (let comparison of response) {
                        const isInDoc = await isInDocument(comparison.original)
                        if (isInDoc) {
                            matchingComparisons.push(comparison)
                        }
                    }
                    onComparisonPipelineExecution(playbook.id, matchingComparisons)
                }).catch(error => {
                    console.error('Error executing playbook comparison pipeline', error)
                }).finally(() => {
                    setIsLoading(false)
                })
            })
        }
    }, [playbook.clauses]);

    useEffect(() => {
        setProgress((activeComparison / playbook.comparisons.length) * 100)
    }, [activeComparison]);

    return (
        <div className={'h-screen w-full flex flex-col gap-2 bg-white px-2 mt-4'}>
            <div className={'flex items-center gap-4'}>
                <Button onClick={() => onBack()}>
                    <ArrowLeft/>
                </Button>
                {playbook.name}
            </div>
            <div>
                <Progress value={progress}/>
            </div>
            <div className={'flex flex-col gap-2 overflow-y-auto'}>
                {isLoading ? <div className={'flex justify-center items-center'}><LoadingSpinner/></div> :
                    playbook.comparisons.map((comparison, index) => (
                        <ComparisonCard
                            key={`comparison-${index}`}
                            comparison={comparison}
                            active={index === activeComparison}
                            index={index}
                            totalCount={playbook.comparisons.length}
                            onApplyChanges={() => {
                                setDialogOpen(true)
                            }}
                            onDismiss={() => {
                                setActiveComparison(prev => prev + 1)
                            }}
                        />
                    ))
                }
            </div>
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogContent className={'bg-white rounded-lg'}>
                    <DialogHeader>
                        <DialogTitle>Apply Changes</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                        Are you sure you want to apply changes?
                    </DialogDescription>
                    <div className={'flex gap-2'}>
                        <Button id={'confirmBtn'} onClick={() => {
                            setActiveComparison(prev => prev + 1)
                            setDialogOpen(false)
                        }}
                                className={'bg-[#5C33FF] text-white'}>Apply</Button>
                        <Button id={'cancelBtn'} onClick={() => setDialogOpen(false)}
                                className={'border border-[#5C33FF] text-[#5C33FF]'}>Cancel</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default PlaybookDetails;
