import * as React from "react";
import Playbook from "./pages/Playbook";
import {ChatProvider} from "../context/chat/ChatProvider";
import {useAuth} from "react-oidc-context";
import AuthPopup from "../auth/popupNavigator";
import {useEffect} from "react";
import {User} from "oidc-client-ts";
import {getConfig} from '../config'


const App: React.FC = () => {
    const auth = useAuth();

    // Event listeners for the popup login.
    //Component did mount
    useEffect(() => {
        window.addEventListener("message", handleMessage);
    }, []);

    //Unmount component
    useEffect(() => {
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    const handleMessage = async (event: MessageEvent) => {
        console.log("Message received.", event);
        const {authority, clientId} = getConfig()
        if (event.data?.source === "oidc-client") {
            console.log("Message received from oidc-client.");
            if (event.data?.user != null) {
                let user = User.fromStorageString(event.data.user);
                console.log("User found in storage:", user?.profile?.email);
                sessionStorage.setItem(
                    `oidc.user:${authority}:${clientId}`, event.data.user
                );
                console.log("User set in storage.");
                await auth.events.load(user, true);
            }
        }
    };

    return (
        <ChatProvider>
            <div className={"h-screen flex items-center justify-center"}>
                {auth.isAuthenticated ? <Playbook/> : <AuthPopup/>}
            </div>
        </ChatProvider>
    );
};
export default App;
