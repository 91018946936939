import {Search} from "lucide-react";
import {Input} from "../components/ui/input";
import PlaybookListItem from "../components/PlaybookListItem";
import React from "react";
import {useChat} from "../../context/chat/ChatContext";
import {Playbook} from "../../services/playbook";

export const PlaybookList: React.FC<{ onPlaybookClick: (playbook: Playbook) => void }> = ({onPlaybookClick}) => {
    const {playbooks} = useChat()
    return (
        <div className={'px-4 py-2'}>
            {playbooks.length > 0 && (
                <div className={'flex items-center border-b border-border'}>
                    <Search/>
                    <Input placeholder={'Search Playbooks'} className={'border-none'}/>
                </div>
            )}
            <div className={'flex flex-col gap-2 my-2 overflow-y-auto'}>
                {playbooks.length > 0 ?
                    playbooks.map(playbook => (
                        <PlaybookListItem
                            key={playbook.id}
                            playbook={playbook}
                            onClick={onPlaybookClick}
                        />
                    )) :
                    <div>
                        <h1 className={'text-xl text-center'}>No playbooks uploaded.</h1>
                    </div>
                }
            </div>
        </div>
    )
}

export default PlaybookList