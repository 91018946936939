import React from "react";
import { UserCircle } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "./ui/dropdown-menu";
import { Button } from "./ui/button";
import { useAuth } from "react-oidc-context";

export const ProfileDropdown = () => {
  const auth = useAuth();

  const handleSignout = async () => {
    await auth.signoutPopup();
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <UserCircle />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-opacity-100 bg-white" align="end">
        <DropdownMenuLabel>My account</DropdownMenuLabel>
        <DropdownMenuItem title={auth.user?.profile.email}>{auth.user?.profile.name}</DropdownMenuItem>
        <DropdownMenuSeparator className="bg-gray-300" />
        <DropdownMenuItem
          className="focus:cursor-pointer focus:outline-gray-400"
          onClick={async () => {
            await handleSignout();
          }}
        >
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
