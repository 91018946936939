const environments = {
  local: require("./config.local.json"),
  development: require("./config.dev.json"),
  // staging: require("./config.staging.json"),
  // production: require("./config.prod.json"),
};

function getEnvironment() {
  // You can set this using a build flag or environment variable
  return process.env.NODE_ENV || "local";
}

export function getConfig() {
  const env = getEnvironment();
  return environments[env] || environments.development;
}
