import {ChatMessage} from "../../services/pipeline";
import {Avatar, AvatarFallback, AvatarImage} from "./ui/avatar";
import AiriaLogo from "../../../assets/airia-logo.svg";
import {Button} from "./ui/button";
import {copyToClipboard} from "../../lib/utils";
import {Check, Copy} from "lucide-react";
import * as React from "react";
import {useAuth} from "react-oidc-context";
import Markdown from "react-markdown";

interface IndividualMessageProps {
    message: ChatMessage
}

const IndividualMessage: React.FC<IndividualMessageProps> = ({message}) => {
    const [isCopied, setIsCopied] = React.useState(false);
    const {user} = useAuth()

    const onCopy = () => {
        setIsCopied(true);
        copyToClipboard(message.text);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    }

    return (
        <div className={'p-2 rounded-lg flex flex-col gap-1'}>
            <div className={'flex justify-between'}>
                <div className={'flex items-center gap-2'}>
                    {message.type === 'assistant'
                        ? <img src={`${AiriaLogo}`}/>
                        : <Avatar className={'bg-gray-500/10 h-6 w-6'}>
                            <AvatarFallback>{user.profile.name.at(0)}</AvatarFallback>
                        </Avatar>}
                    <h3 className={'font-semibold'}>{
                        message.type === 'assistant'
                            ? 'Airia'
                            : user.profile.given_name
                    }</h3>
                </div>
                <Button size={'icon'} variant={'ghost'} className={isCopied ? 'text-green-500' : 'text-[#9C9C9C]'}
                        onClick={() => onCopy()}>
                    {isCopied
                        ? <Check className={'w-5 h-5'}/>
                        : <Copy className={'w-5 h-5'}/>
                    }
                </Button>
            </div>
            {message.type === 'assistant'
                ? <Markdown>
                    {message.text}
                </Markdown>
                : message.text
            }
            <span className={'text-[#B8B8B8]'}>{message.timestamp}</span>
        </div>
    )
}

export default IndividualMessage