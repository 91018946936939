import {Button} from "./ui/button";
import {X} from "lucide-react";
import React from "react";
import {useChat} from "../../context/chat/ChatContext";

interface ChatDrawerHeaderProps {
    onClose: () => void;
}

const ChatDrawerHeader: React.FC<ChatDrawerHeaderProps> = ({ onClose }) => {
    const {isConfidential, setIsConfidential} = useChat()

    return (
        <div className={'flex justify-between'}>
            <div className="flex items-center">
                <h2 className="text-xl font-semibold">Chat</h2>
            </div>
            <div className="flex rounded-full bg-gray-100 p-1 shadow-inner">
                <button
                    onClick={() => setIsConfidential(true)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                        isConfidential
                            ? 'bg-white text-black shadow-sm'
                            : 'text-gray-500 hover:text-gray-700'
                    }`}
                >
                    Safe Mode
                </button>
                <button
                    onClick={() => setIsConfidential(false)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                        !isConfidential
                            ? 'bg-white text-black shadow-sm'
                            : 'text-gray-500 hover:text-gray-700'
                    }`}
                >
                    Open Mode
                </button>
            </div>
            <Button variant={'ghost'} size={'icon'} onClick={onClose}
                    className={'text-[#848996]'}>
                <X/>
            </Button>
        </div>
    )
}

export default ChatDrawerHeader