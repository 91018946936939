import axios from "axios";
import {DataSourceList, BaseProject, DataSourceFileOverview, Dataset} from "./datasetModels";

export const GetAllProjects = async (token: string): Promise<BaseProject[]> => {
    const url = '/platform/api/Project'
    const res = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    if (res.status !== 200) throw new Error(res.status.toString())
    return res.data as BaseProject[]
}

export const GetAllDatasets = async (
    token: string,
    queryParams?: Record<string, string | number> | undefined
): Promise<DataSourceList> => {
    const url = '/platform/api/Datasource'
    const params = new URLSearchParams(queryParams as Record<string, string>)
    const res = await axios.get(url, {
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    if (res.status !== 200) throw new Error(res.status.toString())
    return res.data as DataSourceList
}

export const CreateDataSource = async (data: Dataset, token: string) => {
    const res = await axios.post(`/platform/api/Datasource`, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
    if (res.status >= 300) {
        const errorData = res.data
        throw {status: res.status, message: errorData.message || res.statusText}
    }
}


export const CreateDataSourceFile = async (
    id: string,
    file: File,
    token: string,
    queryParams?: Record<string, string | number> | Record<string, string | undefined> | undefined,
): Promise<DataSourceFileOverview> => {
    const formData = new FormData()
    formData.append('file', file)

    const params = new URLSearchParams(queryParams as Record<string, string>)
    const url =
        `/platform/api/Datasource/${id}/files` +
        (params.size > 0 && params.get('userId') != 'undefined' ? `?${params.toString()}` : '')

    const res = await axios.post(url, formData, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    })

    if (res.status >= 300) throw new Error(res.status.toString())
    if (res.status === 204) {
        return {} as DataSourceFileOverview
    }
    return res.data as DataSourceFileOverview
}