export const prompts = {
    personal: [
        {name: 'Find Compliance Issues', prompt: 'You are a lawyer.Look for compliance issues in the document.'},
        {name: 'Summarize Document in an Email', prompt: 'You are a lawyer.Summarize the document in an email.'},
        {name: 'Research Florida Law', prompt: 'You are a lawyer.Research Florida law.'},
    ],
    company: [
        {name: 'Summarize Cases', prompt: 'Generate a detailed summary of these legal case filings, organized into the following sections: (1) background, (2) legal theories and pertinent facts, (3) takeaways.'},
        {name: 'Summarize Articles', prompt: 'Generate a detailed summary of these legal articles, organized into the following sections: (1) background, (2) legal theories, (3) takeaways.'},
        {name: 'Generate Timeline', prompt: 'Generate a comprehensive and detailed timeline of events from these legal case filings, organized chronologically.'},
    ],
    airia: [
        {name: 'Email to Client re Evidence', prompt: 'Draft an email to my unsophisticated client concerning the evidence that must be preserved now that he has been sued for breach of contract for the contract with ACME corporation.'},
        {name: 'Email to Team re New Case', prompt: 'Draft an email to my associate and my paralegal concerning a new case we will file in federal court alleging fraud and provide bullets concerning discovery and pre-trial motions.'},
        {name: 'Deposition Questions for Witness', prompt: 'Generate a list of important questions that I should ask an unsophisticated witness that I am deposing in a case where I am defending my client from allegations of fraud. My client has been accused of being involved with the manipulation of financial records associated with a series of transactions that occurred during their tenure as procurement officer for the city. The witness is a janitor at the city who was friendly with all the persons involved with the transactions, including my client, and was allegedly present to each of the alleged manipulations of the financial records while mopping the floors at night over a period of three months.'},
        {name: 'Summary of Relevant Law', prompt: 'Provide a summary of the most important cases and the relevant precedent they established concerning statutory subject matter under section 101 of the US Patent Law, the summary being for my team of advanced but junior patent attorney associates, the summary strictly including case law from the last 15 years from the US Supreme Court or the Court of Appeals for the Federal Circuit (CAFC).'},
    ]
}