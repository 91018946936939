import React from "react";
import {CheckCircle, RefreshCw, XCircle} from "lucide-react";
import {Playbook} from "../../services/playbook";
import {LoadingSpinner} from "./ui/loading-spinner";

interface PlaybookListItemProps {
    playbook: Playbook;
    onClick: (playbook: Playbook) => void;

}

const PlaybookListItem: React.FC<PlaybookListItemProps> = ({playbook, onClick}) => {
    const {name, status} = playbook

    const getBgStyle = () => {
        if (status === 'Failed') {
            return 'bg-[#FFDDDD] border-[#C04213]'
        }
        return 'border-border'
    }
    return (
        <div onClick={() => onClick(playbook)}
             className={`p-4 rounded-lg flex justify-between cursor-pointer border ${getBgStyle()}`}>
            <span>{name}</span>
            {status === 'Processing' && <LoadingSpinner />}
            {status === 'Completed' && <CheckCircle className={'text-[#25C822]'}/>}
            {status === 'Failed' && <XCircle className={'text-[#C04213]'}/>}
        </div>
    )
}

export default PlaybookListItem