import React from 'react'
import {Comparison, ComparisonV2} from "../../services/playbook";
import {Button} from "./ui/button";
import {replaceSync} from "../../lib/utils";

interface ComparisonCardProps {
    comparison: ComparisonV2;
    active: boolean
    index: number;
    totalCount: number;
    onApplyChanges?: () => void;
    onDismiss?: () => void;
}

const ComparisonCard: React.FC<ComparisonCardProps> = ({active, comparison, index, totalCount, onApplyChanges, onDismiss}) => {

    const onApplyClick = () => {
        replaceSync(comparison.original, comparison.suggested)
        onApplyChanges()
    }

    return (
        <div
            className={`flex flex-col p-3 gap-2 rounded-lg border ${active ? 'border-2 border-[#5C33FF]' : 'border-[#EAEAEA]'}`}>
            <div className={'flex justify-between px-2'}>
                <span>{comparison.reason}</span>
                <span className={'whitespace-nowrap text-[#606062'}>{`${index + 1} of ${totalCount}`}</span>
            </div>
            <div className={'border border-[#EAEAEA] p-3'}>
                <span className={'text-[#606062]'}>{comparison.suggested}</span>
            </div>
            {active && (
                <div className={'flex gap-2'}>
                    <Button className={'bg-[#5C33FF] text-white'} onClick={() => onApplyClick()}>Apply Changes</Button>
                    <Button className={'border border-[#5C33FF] text-[#5C33FF]'}
                            onClick={() => onDismiss()}>Dismiss</Button>
                </div>
            )}
        </div>
    )
}

export default ComparisonCard
