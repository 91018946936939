import React from 'react';
import {useAuth} from 'react-oidc-context';
import {PopupUtils} from "../utils/popupUtils";
import AppBg from "../taskpane/assets/airia-addon-bg.png";
import AiriaLogo from "../taskpane/assets/airia-logo-full.png";
import {Button} from "../taskpane/components/ui/button";
import {ArrowRight} from "lucide-react";

function PopupNavigator() {
    const auth = useAuth();
    const openPopup = async () => {
        console.log("Opening authentication popup...");
        try {
            if (!auth.isAuthenticated) {
                const centeredPopup = PopupUtils.center({width: 800, height: 640, toolbar: false, location: false});
                const url = window.location.origin + '/authpopup.html';
                let windowRef = window.open(url, 'authPopup', PopupUtils.serialize(centeredPopup));
                console.log("Got window reference:", windowRef);
                if (windowRef) {
                    console.log("Navigating to:", url);
                    windowRef.location.href = url;
                    windowRef.focus();
                }
            } else {
                console.log("User already signed in:", auth.user?.profile?.email);
            }
        } catch (error) {
            console.error("Error logging in:", error);
        }
    };

    return (
        <div className={"h-screen w-screen flex items-center justify-center"}>
                <div
                    className={`h-full w-full bg-[url('${AppBg}')] bg-no-repeat bg-cover bg-right flex flex-col justify-center items-center gap-10 p-4`}>
                    <div className={'flex flex-col gap-4 items-center'}>
                        <img src={`${AiriaLogo}`} alt={'Airia logo'} className={'w-[100px]'}/>
                        <h3 className={'text-sm text-[#3d3d39]'}>Enterprise AI Simplified</h3>
                    </div>
                    <Button variant={'default'} disabled={auth.isAuthenticated} onClick={() => openPopup()}
                            className={'w-full bg-[#007AFF] text-white rounded-full'}>
                        <span className={'mr-1'}>Login</span>
                        <ArrowRight className={'h-4 w-4'}/>
                    </Button>
                </div>
        </div>
    );
}

function AuthPopup() {
    return (
        <PopupNavigator/>
    );
}

export default AuthPopup;