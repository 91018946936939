import {Lock, LockOpen} from "lucide-react";
import AiriaLogo from "../../../assets/airia-logo.svg";
import {Separator} from "../components/ui/separator";
import IndividualMessage from "../components/IndividualMessage";
import {LoadingSpinner} from "../components/ui/loading-spinner";
import UserChatInput from "../components/UserChatInput";
import * as React from "react";
import {ChatMessage, ExecuteChatPipeline, ExecutePrivateChatPipeline} from "../../services/pipeline";
import {getDocumentText} from "../../lib/utils";
import {FormEvent, useEffect, useRef, useState} from "react";
import {useChat} from "../../context/chat/ChatContext";
import PromptLibrary from "../components/PromptLibrary";
import {useAuth} from "react-oidc-context";
import {ScrollArea} from "../components/ui/scroll-area";

const Chat: React.FC = () => {
    const [inputText, setInputText] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const {
        isConfidential,
        setIsLibraryOpen,
        isLibraryOpen,
        confidentialMessages,
        setConfidentialMessages,
        openMessages,
        setOpenMessages
    } = useChat()
    const [messages, setMessages] = React.useState<ChatMessage[]>(isConfidential ? confidentialMessages : openMessages);
    const {user} = useAuth()
    const scrollAreaRef = useRef<HTMLDivElement>(null)
    const [shouldAutoScroll, setShouldAutoScroll] = useState(true)

    useEffect(() => {
        const scrollElement = scrollAreaRef.current?.querySelector(
            '[data-radix-scroll-area-viewport]'
        )
        if (!scrollElement) return () => {}

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = scrollElement
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10 // 10px threshold
            setShouldAutoScroll(isAtBottom)
        }

        scrollElement.addEventListener('scroll', handleScroll)
        return () => scrollElement.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        if (shouldAutoScroll) {
            scrollToBottom()
        }
    }, [messages, shouldAutoScroll])

    useEffect(() => {
        if (isConfidential) {
            setMessages(confidentialMessages)
        } else {
            setMessages(openMessages)
        }
    }, [isConfidential, confidentialMessages, openMessages]);

    const scrollToBottom = () => {
        const scrollElement = scrollAreaRef.current?.querySelector(
            '[data-radix-scroll-area-viewport]'
        )
        if (scrollElement) {
            scrollElement.scrollTop = scrollElement.scrollHeight
        }
    }

    const executePipelineRequest = async (inputText: string) => {
        try {
            const document = await getDocumentText();
            if (isConfidential) {
                return await ExecutePrivateChatPipeline("Here is a contract: \n" + document + "\n\n" + inputText, user.access_token);
            } else {
                return await ExecuteChatPipeline("Here is a contract: \n" + document + "\n\n" + inputText, user.access_token);
            }
        } catch (error) {
            throw error;
        }
    }

    const onSuggestionClick = async (suggestion: string) => {
        await sendMessage(suggestion)
    }

    const onMessageSend = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const message = inputText.trim();
        setInputText('');
        await sendMessage(message);
    }

    const sendMessage = async (message: string) => {
        try {
            setIsLibraryOpen(false)
            setIsLoading(true)
            if (isConfidential) {
                setConfidentialMessages(prev => [...prev, {
                    type: 'user',
                    text: message,
                    timestamp: new Date().toLocaleString()
                }])
                const response = await executePipelineRequest(message)
                setConfidentialMessages(prev => [...prev, response])
            } else {
                setOpenMessages(prev => [...prev, {
                    type: 'user',
                    text: message,
                    timestamp: new Date().toLocaleString()
                }])
                const response = await executePipelineRequest(message)
                setOpenMessages(prev => [...prev, response])
            }
        } catch (error) {
            console.error('Error sending message:', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="relative w-full h-[calc(100%-90px)] flex flex-col justify-between bg-white pt-4">
            <ScrollArea ref={scrollAreaRef}>
                {messages.length <= 0 &&
                    <>
                        <IndividualMessage message={{
                            type: 'assistant',
                            text: 'How can I help?',
                            timestamp: new Date().toLocaleString()
                        }}/>
                        <div className={'flex flex-col gap-4 mt-4'}>
                            <div className={'flex justify-evenly items-center'}>
                                <Separator className={'max-w-[100px] bg-[#e3e3e3]'}/>
                                <div
                                    className={`flex items-center bg-[${isConfidential ? '#E6E1FA' : '#FCF3DF'}] text-[${isConfidential ? '#1E1992' : '#C38100'}] rounded-full py-1 px-2 gap-1`}>
                                    {isConfidential ? <Lock className="w-4 h-4"/> : <LockOpen className="w-4 h-4"/>}
                                    <span>{isConfidential ? 'Safe' : 'Open'}</span>
                                </div>
                                <Separator className={'max-w-[100px] bg-[#e3e3e3]'}/>
                            </div>
                            <span
                                className={`text-center text-[${isConfidential ? '#5C33FF' : '#F4AC20'}] text-sm px-10`}>
                                {isConfidential ? 'Using Approved LLM' : 'Using Public LLM'}
                            </span>
                            <span className={'text-center text-[#7B7B7B] text-sm px-10'}>
                                {
                                    isConfidential
                                        ? 'Everything in this conversation will be safely stored within the firm’s servers'
                                        : 'Everything in this conversation will be will be exposed to external servers'
                                }
                            </span>
                        </div>
                    </>
                }
                {messages.length > 0 && <div>
                    <IndividualMessage message={{
                        type: 'assistant',
                        text: 'How can I help?',
                        timestamp: new Date().toLocaleString()
                    }}/>
                    {messages.map((message, index) => (
                        <IndividualMessage key={index} message={message}/>
                    ))}
                </div>}
                {isLoading && (
                    <div className={'flex justify-center'}>
                        <LoadingSpinner/>
                    </div>
                )}
            </ScrollArea>
            <div className={'flex flex-col w-full'}>
                {isLibraryOpen && <PromptLibrary onPromptClick={onSuggestionClick}/>}
                <UserChatInput onMessageSend={onMessageSend} onChange={setInputText} value={inputText}/>
            </div>
        </div>
    )
}

export default Chat